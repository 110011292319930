import React from "react";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import HeroImage from "../assets/images/hero.png";
import socialMedia from "../utils/data/socialMedia";
import Works from "../utils/data/howItWorks";
import Benefits from "../utils/data/benefits";
import Crypto1 from "../assets/images/crypto-one.png";
import Crypto2 from "../assets/images/crypto-two.png";
import RightCoin from "../assets/images/rightCoin.png";
import LeftCoin from "../assets/images/leftCoin.png";
import LeftCoinMbl from "../assets/images/leftCoinMbl.png";
import BenefitsImg from "../assets/images/benefits.png";
import { ReactComponent as MyArbit } from "../assets/svg/MyArbit.svg";
import { ReactComponent as WhyChoose } from "../assets/svg/WhyChoose.svg";
import { ReactComponent as Investment } from "../assets/svg/Investment.svg";
import { ReactComponent as Transaction } from "../assets/svg/Transaction.svg";
import { ReactComponent as Withdraw } from "../assets/svg/Withdraw.svg";
import { ReactComponent as EasyToGrow } from "../assets/svg/EasyToGrow.svg";
import { ReactComponent as Flexible } from "../assets/svg/Flexible.svg";
import { ReactComponent as Reward } from "../assets/svg/Reward.svg";
import { ReactComponent as Direction } from "../assets/svg/Direction.svg";
import { ReactComponent as ArrowDown } from "../assets/svg/ArrowDown.svg";

import WorkCard from "../components/WorkCard";
import BenefitCard from "../components/BenefitCard";

import { Slide, Zoom, Fade } from "react-swift-reveal";
import Partners from "../components/Partners";
import BlogSection from "../components/BlogSection";

function Home() {
  const loginUrl = "https://dashboard.myarbit.com/login";
  const registerUrl = "https://dashboard.myarbit.com/page-register?signUp=true";

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="circle blueCircle"></div>
          <Slide right duration={2000} delay={50}>
            <div className="rightCoin">
              <img src={RightCoin} alt="investing platforms" />
            </div>
          </Slide>
          <Slide left duration={2000} delay={50}>
            <div className="leftCoinMbl">
              <img src={LeftCoinMbl} alt="best investing website" />
            </div>
          </Slide>
          <div className="btnWrapper">
            {loginUrl && (
              <Link to={loginUrl} target="_blank" className="btn login">
                Login
              </Link>
            )}
            {registerUrl && (
              <Link to={registerUrl} target="_blank" className="btn register">
                Register
              </Link>
            )}
          </div>
          <Zoom duration={3000} delay={100}>
            <h1 className="heading">
              A better way to manage your <span>[digital]</span> money
            </h1>
          </Zoom>
          <Fade duration={3000} delay={200}>
            <div className="subHeading">
              We are deeply committed to revolutionizing how investors utilize
              the best crypto trading platform to navigate the ever-evolving
              realm of cryptocurrencies.
            </div>
          </Fade>
          <div className="leftCoin">
            <img src={LeftCoin} alt="investment website" />
          </div>
          {registerUrl && (
            <Link to={registerUrl} target="_blank" className="join">
              Join myarbit
            </Link>
          )}
        </div>
      </section>
      <div className="container">
        <div className="heroBottom">
          <div className="circle pinkCircle"></div>
          <div className="text">Unlock the true power of Crypto Staking</div>
          <div className="heroImg">
            <img src={HeroImage} alt="investing firm" />
          </div>
          <div className="socialMedia">
            {socialMedia.map((e) => (
              <Link to={e.path} target="_blank" key={e.id}>
                {e.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <section className="whyChoose container">
        <WhyChoose className="bg" />
        <div className="circle blueCircle"></div>
        <div className="gridWrapper">
          <div className="leftWrapper">
            <h2 className="heading">
              Why Choose <MyArbit className="myArbit" />
            </h2>
            <h3 className="subHeading">
              Empowering Investors Through Crypto Arbitrage Innovation
            </h3>
            <div className="desc">
              Trust is the cornerstone of our success. We are one of the leading
              investing platforms; we operate with the highest integrity and
              prioritize the security of your investments.
            </div>
          </div>
          <div className="rightWrapper">
            <div className="desc">
              MyArbit is committed to transforming cryptocurrency investing with
              our top-tier online investing platform. As a prominent online
              investing platform, we combine advanced technology and expert
              knowledge to offer unmatched opportunities for our investors. Our
              platform provides accessibility, security and profitable benefits
              in crypto arbitrage, ensuring a smooth path to financial success.
            </div>
            <div className="desc">
            Trading in volatile markets poses high risks due to rapid, unpredictable price swings driven by news, sentiment, or economic changes. While offering potential gains, it can lead to significant losses, especially with leverage. Effective risk management, disciplined strategies, and staying informed are crucial to navigating such unpredictable market conditions successfully.
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <div className="container">
          <div className="row">
            <div className="descBox">
              <Slide left duration={3000} delay={100}>
                <h2 className="heading">
                  <Logo /> Features
                </h2>
              </Slide>
              <Slide left duration={3000} delay={200}>
                <div className="desc">
                  At MyArbit, we take great satisfaction in offering a
                  feature-rich platform made to make Crypto investing simple,
                  safe, and profitable. As a pioneer in online investment, we
                  differentiate ourselves in the competitive world of
                  cryptocurrency investing by utilizing advanced technology,
                  in-depth knowledge, and strong security protocols.
                </div>
              </Slide>
            </div>
            <div className="icon-box easy">
              <Zoom duration={3000} delay={350}>
                <EasyToGrow />
              </Zoom>
              <div className="subHeading">Easy to Grow</div>
            </div>
            <div className="icon-box transaction">
              <Zoom duration={3000} delay={250}>
                <Transaction />
              </Zoom>
              <div className="subHeading">Safe Transactions</div>
            </div>
          </div>
          <div className="row second">
            <div className="icon-box bl-0 flexible">
              <Zoom duration={3000} delay={300}>
                <Withdraw />
              </Zoom>
              <div className="subHeading">Flexible Withdraw</div>
            </div>
           
            <div className="icon-box profitiers">
              <Zoom duration={3000} delay={400}>
                <Flexible />
              </Zoom>
              <div className="subHeading">Flexible Profitiers</div>
            </div>
            <div className="icon-box reward">
              <Zoom duration={3000} delay={450}>
                <Reward />
              </Zoom>
              <div className="subHeading">Referral Reward</div>
            </div>
            <div className="icon-box reward">
              <Zoom duration={3000} delay={450}>
              <Investment />
              </Zoom>
              <div className="subHeading">Risk  Involved</div>
            </div>
          </div>
        </div>
      </section>
      <Partners />
      <section className="howItWorks">
        <div className="container">
          <Slide left duration={1500} delay={100}>
            <h2 className="heading">
              How does <span>MyArbit</span> works?
            </h2>
          </Slide>
          <div className="cardWrapper">
            {Works.map((card) => (
              <WorkCard {...card} key={card.id} />
            ))}
            <div className="circle pinkCircle"></div>
          </div>
        </div>
      </section>
      <section className="crypto">
        <div className="container">
          <Slide left duration={1500} delay={100}>
            <h1 className="heading">
              Turning crypto <span className="blue">complexity</span> into an
              <span className="pink"> opportunity</span>
            </h1>
          </Slide>

          <div className="gridWrapper">
            <div className="leftWrapper">
              <div className="box">
                <img src={Crypto1} alt="investments business" />
              </div>
            </div>
            <div className="rightWrapper">
              <div className="box">
                <img src={Crypto2} alt="online investing" />
              </div>
            </div>
            <div className="circle blueCircle"></div>
          </div>
          <div className="cryptoBottom">
            <Slide left duration={1500} delay={100}>
              <div className="leftHeading">MyArbit is simple & fun to use</div>
            </Slide>
            <Direction class="smNone" />
            <ArrowDown class="mdNone" />
            <Slide right duration={1500} delay={200}>
              <div className="rightHeading">
                All your digital money in one sleek environment, with 24/7 live
                support always there to help.
                <div>
                  {registerUrl && (
                    <Link
                      to="https://dashboard.myarbit.com/page-register?signUp=true"
                      target="_blank"
                    >
                      Register
                    </Link>
                  )}
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </section>
      <section className="benefits">
        <div className="container">
          <div className="circle pinkCircle"></div>
          <Fade duration={3000}>
            <h2 className="heading">Benefits</h2>
          </Fade>
          <Fade duration={3000} delay={100}>
            <h3 className="subHeading">
              Personalized, diversified and optimized
            </h3>
          </Fade>
          <Fade duration={3000} delay={200}>
            <div className="desc">
              Our platform is designed to make crypto arbitrage accessible,
              secure, and rewarding, providing you with a seamless journey
              towards financial prosperity.
            </div>
          </Fade>

          <div className="rightImg">
            <img src={BenefitsImg} alt="online investment" />
          </div>
          {Benefits.map((benefit) => (
            <div className="benefitWrapper" key={benefit.id}>
              <div className="number">{benefit.number}</div>
              <div className="rightWrapper">
                <div className="title">{benefit.title}</div>
                <div className="shortDesc">{benefit.desc}</div>
                {benefit?.cards && (
                  <div className="cardWrapper">
                    {benefit?.cards.map((card) => (
                      <Fade duration={3000} delay={200}>
                        <div className="card" key={card.id}>
                          <div className="cardTitle">{card.title}</div>
                        </div>
                      </Fade>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
      <BenefitCard />
      <BlogSection />
    </>
  );
}

export default Home;
